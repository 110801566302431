import React from "react";

import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: "100%",
    height: 48,
    right: 0,
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  buttonMobile: {
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: "100%",
    height: 48,
    right: 0,
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const AddToCartBtn = ({ onClick, disabled, isScrollable }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Button
      className={isMobile ? classes.buttonMobile : classes.button}
      size="large"
      onClick={onClick}
      disabled={disabled}
    >
      Add to order
    </Button>
  );
};

export default AddToCartBtn;
