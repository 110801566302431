/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";

import { Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { logInfo } from "../../../../../services/logging";
import { getAvailabilityOfItem } from "../../../../../utils/availability";
import { getItemCat } from "../../../../../utils/utils";
import MenuItemDetails from "../MenuItemDetails";
import BuildYourItem from "./BuildYourItem";
import MultiDirectItem from "./MultiDirectItem";
import SingleDirectItem from "./SingleDirectItem";

const MenuItemDisplayModal = styled(Dialog)`
  .MuiDialog-container {
    @media (max-width: 600px) {
    }
  }
  .MuiDialog-paperWidthSm {
    @media (max-width: 600px) {
    }
  }
  .MuiDialog-paper {
    /* max-height: 568px;   */
    overflow: hidden;
  }
  .MuiDialog-scrollBody:after {
    /* display: block */
  }
  .MuiDialog-scrollBody:after {
    /* display: block */
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // color: theme.palette.text.itemTextColor,
    overflowX: "hidden",
    // height: "100%",
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: "unset",
      },
    },
    "& .MuiDialog-paper": {
      [theme.breakpoints.up("md")]: {
        minHeight: 300,
      },
    },
  },
  content: {
    height: "100%",
    marginBottom: 104,
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  PaperProps: {
    paperWidthSm: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "8px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 1000,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightSideSection: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 42,
    },
  },
  rightSideSectionScroll: {
    overflowY: "auto",
    maxHeight: 400,
    [theme.breakpoints.up("md")]: {
      paddingTop: 42,
    },
    ...theme.palette.scrollbarStyles,
  },
  contentSection: {
    maxHeight: 300,
    overflow: "auto",
    ...theme.palette.scrollbarStyles,
  },
  paperFullWidth: {
    "&.MuiDialog-paperFullWidth": {
      backgroundColor: theme.palette.background.menuBackgroundColor,
    },
  },
  dialogContainer: {
    "&.MuiDialog-container": {
      height: "unset",
    },
  },
}));

const renderContent = (
  selectedItemData,
  handleClose,
  catMenuId,
  isAvailable,
  isOutOfStock,
  embedType,
) => {
  if (getItemCat(selectedItemData) === "singleDirect") {
    return (
      <SingleDirectItem
        selectedItemData={selectedItemData}
        handleClose={handleClose}
        catMenuId={catMenuId}
        isAvailable={isAvailable}
        isOutOfStock={isOutOfStock}
        embedType={embedType}
      />
    );
  }
  if (getItemCat(selectedItemData) === "multiDirect") {
    return (
      <MultiDirectItem
        selectedItemData={selectedItemData}
        handleClose={handleClose}
        catMenuId={catMenuId}
        isAvailable={isAvailable}
        isOutOfStock={isOutOfStock}
        embedType={embedType}
      />
    );
  }
  if (getItemCat(selectedItemData) === "buildYourOwn") {
    return (
      <BuildYourItem
        selectedItemData={selectedItemData}
        handleClose={handleClose}
        catMenuId={catMenuId}
        isAvailable={isAvailable}
        isOutOfStock={isOutOfStock}
        embedType={embedType}
      />
    );
  }
  return null;
};

// eslint-disable-next-line react/jsx-props-no-spreading

const MenuItemModal = ({
  open,
  handleClose,
  selectedItemData,
  catMenuId,
  topOffset,
  selectedDeliveryMethod,
  selectedDateTime,
  catMenuOptions,
}) => {
  const classes = useStyles();
  const [itemOptions, setItemOptions] = useState({});
  const [isAvailable, setIsAvailable] = useState(true);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const { embedType } = useSelector((state) => state.global);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (open) {
      setItemOptions({});
      setIsAvailable(true);

      const { menuId, catId } = selectedItemData;

      const { option, isItemAvailable, isItemActive } = getAvailabilityOfItem(
        menuId,
        catId,
        catMenuId,
        catMenuOptions,
        selectedDeliveryMethod,
        selectedDateTime,
      );
      logInfo({
        message: "Get availability of item on modal open",
        itemData: {
          menuId,
          option,
          isItemAvailable,
          isItemActive,
        },
      });
      setIsAvailable(isItemAvailable);
      setIsOutOfStock(!isItemActive);
      if (option) setItemOptions(option);
    } else {
      setItemOptions({});
      setIsAvailable(true);
      setIsOutOfStock(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (_.isEmpty(selectedItemData)) return null;

  const { title, desc } = selectedItemData;
  const { price, imageUrl } = selectedItemData.data[0];

  const handleModalClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  return (
    <>
      {isMobile ? (
        <Dialog
          onClose={handleModalClose}
          open={open}
          classes={{
            root: classes.root,
            paperFullWidth: classes.paperFullWidth,
          }}
          fullScreen={isMobile} // Fullscreen on mobile
          maxWidth={isMobile ? false : "sm"} // Disable maxWidth on mobile
          fullWidth
          aria-labelledby="login-dialog"
          aria-describedby="login-dialog-content"
        >
          <IconButton
            aria-label="close"
            className={ classes.closeButton}
            onClick={handleClose}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
          {/* <Grid container id="menuItemViewModal" className={classes.content}>
          <Grid item sm={12} md={6} style={{ width: "100%" }}>
            <MenuItemDetails
              image={imageUrl}
              title={title}
              desc={desc}
              price={price}
              itemOptions={itemOptions}
              isOutOfStock={isOutOfStock}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <div
              className={
                embedType === "WIX"
                  ? classes.rightSideSectionScroll
                  : classes.rightSideSection
              }
            >
              {renderContent(
                selectedItemData,
                handleClose,
                catMenuId,
                isAvailable,
                isOutOfStock,
                embedType,
              )}
            </div>
          </Grid>
        </Grid> */}
          <Grid id="menuItemViewModal" className={classes.content}>
            <MenuItemDetails
              image={imageUrl}
              title={title}
              desc={desc}
              price={price}
              itemOptions={itemOptions}
              isOutOfStock={isOutOfStock}
            />
            <div
              className={embedType === "WIX" ? classes.contentSection : null}
            >
              {renderContent(
                selectedItemData,
                handleClose,
                catMenuId,
                isAvailable,
                isOutOfStock,
                embedType,
              )}
            </div>
          </Grid>
        </Dialog>
      ) : (
        <MenuItemDisplayModal
          disableEscapeKeyDown
          fullWidth
          keepMounted={false}
          maxWidth="sm"
          id="menuItemViewModal"
          scroll="body"
          onClose={handleModalClose}
          open={open}
          classes={{
            root: classes.root,
            paperFullWidth: classes.paperFullWidth,
          }}
          style={{ marginTop: topOffset }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
          {/* <Grid container id="menuItemViewModal" className={classes.content}>
          <Grid item sm={12} md={6} style={{ width: "100%" }}>
            <MenuItemDetails
              image={imageUrl}
              title={title}
              desc={desc}
              price={price}
              itemOptions={itemOptions}
              isOutOfStock={isOutOfStock}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <div
              className={
                embedType === "WIX"
                  ? classes.rightSideSectionScroll
                  : classes.rightSideSection
              }
            >
              {renderContent(
                selectedItemData,
                handleClose,
                catMenuId,
                isAvailable,
                isOutOfStock,
                embedType,
              )}
            </div>
          </Grid>
        </Grid> */}
          <Grid id="menuItemViewModal" className={classes.content}>
            <MenuItemDetails
              image={imageUrl}
              title={title}
              desc={desc}
              price={price}
              itemOptions={itemOptions}
              isOutOfStock={isOutOfStock}
            />
            <div
              className={embedType === "WIX" ? classes.contentSection : null}
            >
              {renderContent(
                selectedItemData,
                handleClose,
                catMenuId,
                isAvailable,
                isOutOfStock,
                embedType,
              )}
            </div>
          </Grid>
        </MenuItemDisplayModal>
      )}
    </>
  );
};

// function modalPropsEqual(prevProps, nextProps) {
//   return (
//     prevProps.selectedItemData?.menuId === nextProps.selectedItemData?.menuId
//     && prevProps.open === nextProps.open
//   );
// }

// export default memo(MenuItemModal, modalPropsEqual);
export default MenuItemModal;
