import React, { useState } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  useTheme,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import isEmpty from "lodash/isEmpty";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { logInfo } from "../../../../../../services/logging";
import FacebookPixel from "../../../../../../utils/analytics/FacebookPixel";
import { gaEventAddToCart } from "../../../../../../utils/googleAnalytics";
import {
  formatPrice,
  postMessageToParentWindow,
} from "../../../../../../utils/utils";
import AddToCartBtn from "../../../../../common/AddToCartBtn";
import QuantitySection from "../../../../../common/QuantitySection";
import { addItemToCartAsync } from "../../../MyOrder/redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    display: "flex",
  },
  sectionHeading: {},
  label: {
    width: "100%",
    marginRight: 24,
    alignItems: "baseline",
  },
  price: {
    marginLeft: 8,
  },
  radioGroup: {
    padding: 8,
  },
  radio: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  radioButton: {
    // color: theme.palette.primary.main,
  },
  ItemLabel: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.itemTextColor,
  },
}));

const MultiDirectItem = ({
  selectedItemData,
  actions,
  handleClose,
  catMenuId,
  isAvailable,
  embedType,
}) => {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState("");
  const theme = useTheme();
  const {
    storeInfo: { businessDisplayName },
  } = useSelector((state) => state.store);
  const { categorySelected } = useSelector((state) => state.category);
  const { activeCatMenu } = useSelector((state) => state.catMenu);

  const addItemToCart = async () => {
    setError("");
    if (isEmpty(selectedItem)) return setError("Please select an option.");
    const cartItem = {};
    cartItem.itemData = selectedItemData;
    cartItem.catMenuId = catMenuId;
    cartItem.applicableDiscounts = [];
    cartItem.item = selectedItem;
    cartItem.quantity = quantity;
    cartItem.type = "multiDirect";
    cartItem.totalPrice = (quantity * parseFloat(selectedItem.price)).toFixed(
      2,
    );
    cartItem.oneUnitPrice = parseFloat(selectedItem.price).toFixed(2);

    if (!theme?.view?.isMobile && theme?.view?.isFixedList) {
      if (embedType === "WIX") {
        actions.addItemToCartAsync(cartItem);
      } else {
        postMessageToParentWindow({
          addItemToCart: true,
          cartItemNode: cartItem,
        });
      }
    } else {
      actions.addItemToCartAsync(cartItem);
    }
    logInfo({ message: "Item added to cart" });
    handleClose();
    gaEventAddToCart(
      cartItem,
      quantity,
      categorySelected,
      activeCatMenu,
      businessDisplayName,
      cartItem.oneUnitPrice,
    );
    FacebookPixel.addToCart(cartItem, categorySelected);
    // if (storeInfo?.tikTokAnalyticsId) {
    //   TicktockPixel.addToCart(cartItem, categorySelected);
    // }
  };

  const ItemLabel = ({ title, price }) => (
    <Box component="div" className={classes.ItemLabel}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography className={classes.price} variant="subtitle2">
        {formatPrice(price)}
      </Typography>
    </Box>
  );

  const multiItems = selectedItemData.data;
  return (
    <>
      <FormControl component="div" className={classes.root}>
        {error && <Alert severity="error">{error}</Alert>}
        <RadioGroup
          row
          style={{ justifyContent: "space-between" }}
          aria-label="chooseYourItem"
          name="chooseYourItem"
          className={classes.radioGroup}
        >
          {multiItems.map((i) => (
            <FormControlLabel
              classes={{ root: classes.radio, label: classes.label }}
              key={`chooseItem-${i.itemId}`}
              value={i.itemId}
              control={
                <Radio
                  color="primary"
                  classes={{ root: classes.radioButton }}
                />
              }
              label={<ItemLabel title={i.size} price={i.price} />}
              labelPlacement="end"
              onClick={() => setSelectedItem(i)}
              checked={selectedItem.itemId === i.itemId}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {theme?.view?.isMobile && <div style={{ height: "90px" }}></div>}
      <QuantitySection quantity={quantity} setQuantity={setQuantity} />
      <AddToCartBtn onClick={addItemToCart} disabled={!isAvailable} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addItemToCartAsync }, dispatch),
});

export default connect(null, mapDispatchToProps)(MultiDirectItem);
