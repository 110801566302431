/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";

import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { asyncLocationInitData } from "../../app/redux/actions";
import { setIsDineIn } from "../../redux/actions/global";
import { getCombinedLocationInfo } from "../../services/locationService";
// import { checkOrder } from "../../services/orderService";
// import { getLocalStore } from "../../utils/storage/localStorage";
import { logError, logInfo } from "../../services/logging";
import {
  fetchCatMenuListAsync,
  fetchCatMenuOptionsAsync,
  fetchCustomerListAsync,
  fetchLoyaltyListAsync,
} from "../@pages/storeHome/CatMenu/redux/actions";
import { setLocationId } from "../@pages/storeHome/MyOrder/redux/actions";
import {
  asyncFetchOpenTimes,
  asyncFetchOrderRelatedInfo,
  asyncFetchStoreInfo,
  setOtherStoreInfo,
} from "../@pages/storeHome/redux/actions";
import {
  clearLocalStoreOnOrderSuccess,
  getLocalStore,
  setLocalStore,
} from "../../utils/storage/localStorage";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../../services/customerService";
import store from "../../redux";

const withInitDataFetch = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const customerId = getLocalStore("customerId");
  const shortId = getLocalStore("shortId");
  const { isAuthenticated } = useSelector((state) => state.auth);

  const token = getLocalStore("idToken");

  const {
    customer: { firstName, lastName },
  } = useSelector((state) => state.contact);
  // const history = useHistory();
  const { isDineIn } = qs.parse(location.search, { parseBooleans: true });

  // const updateOrderId = async () => {
  //   try {
  //     let orderId = getLocalStore("orderId");
  //     if (orderId) {
  //       orderId = JSON.parse(orderId);
  //       try {
  //         const res = await checkOrder(params.locationId, orderId);
  //         const { status } = res.data.data;
  //         if (status !== "started" || status === "checkout") {
  //           history.push(`/location/${params.locationId}/shop`);
  //         }
  //       } catch (error) {
  //         history.push(`/location/${params.locationId}/shop`);
  //       }
  //     } else {
  //       history.push(`/location/${params.locationId}/shop`);
  //     }
  //   } catch (error) {
  //     alert(
  //       "Error checking order status. Please check your connection",
  //     );
  //   }
  // };

  const fetchCombinedStoreInfo = async () => {
    try {
      const res = await getCombinedLocationInfo(params.locationId);
      const {
        isOrderingActive,
        isPreOrderActive,
        serviceCharge,
        carrierBagCharge,
        scheduleDelay,
      } = res.data.data;

      logInfo({
        message: "Combined location info",
        isOrderingActive,
        isPreOrderActive,
        serviceCharge: isDineIn ? "0.00" : serviceCharge,
        carrierBagCharge,
      });

      dispatch(
        setOtherStoreInfo({
          isOrderingActive,
          isPreOrderActive,
          serviceCharge: isDineIn ? "0.00" : serviceCharge,
          carrierBagCharge,
          scheduleDelay,
        }),
      );
    } catch (error) {
      logError({ message: "Error fetching combined location info", error });
      alert("Error fetching store data. Please check your connection");
    }
  };

  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    store.dispatch({
      type: "RESET",
    });
  };

  const handleGetCustomerInfo = async () => {
    try {
      const response = await getCustomerValidationNew(token); // Validate customer
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
    } catch (error) {
      const response = await customerUpdateNew(token, firstName, lastName);
      if (!firstName || !lastName) {
        storeCleanup();
      }
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
    }
  };

  useEffect(() => {
    dispatch(fetchCatMenuListAsync(params.locationId));
    dispatch(asyncFetchOrderRelatedInfo(params.locationId));
    dispatch(asyncLocationInitData(params.locationId));
    dispatch(asyncFetchStoreInfo(params.locationId));
    dispatch(asyncFetchOpenTimes(params.locationId));
    dispatch(fetchCatMenuOptionsAsync(params.locationId));
    fetchCombinedStoreInfo();
    dispatch(setIsDineIn());
    // updateOrderId();
    dispatch(setLocationId(params.locationId));
  }, [dispatch, params.locationId]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchLoyaltyListAsync(params.locationId, isAuthenticated));
      dispatch(fetchCustomerListAsync(params.locationId));
    }

    if (isAuthenticated && (!customerId || !shortId)) {
      handleGetCustomerInfo();
    }
  }, [isAuthenticated]);

  return <WrappedComponent {...props} />;
};

export default withInitDataFetch;
