import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import appleIcon from "../../../../../assets/icons/apple_logo.png";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import { useDispatch, useSelector } from "react-redux";
import { AppleSignInUser } from "../../../../../utils/AppleSignInUser";

const AppleSignIn = ({
  setAuthError,
  isNeedDisabled,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setAppleFirstName,
  setToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );

  const handleClick = async () => {
    AppleSignInUser(
      isNeedDisabled,
      setIsLoading,
      setAuthError,
      history,
      params,
      location,
      dispatch,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setAppleFirstName,
      setToken,
      isActiveLoyalty,
    );
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={appleIcon}
      labelText="Sign in with Apple"
      alt="Apple sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default AppleSignIn;
